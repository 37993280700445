.casesContainer {
    max-width: 980px;
    padding: 0;
    text-align: center;
}

.casesCard {
    border-radius: 15px !important;
    margin: 15px;
    font-weight: 300 !important;
    display: inline-block;
    min-width: 30%;
    max-width: 50%;
    width: 280px;
    min-height: 350px;

    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    -ms-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation: fadein 1s;
}

.casesRipple {
    display: block !important;
}

.casesImg {
    display: block;
    width: 100%;
    height: 150px !important;
    object-fit: cover;
}

.casesBody {
    font-size: 17px;
    line-height: 1.7;
    color: #3d3d3d;
    max-height: 220px;
    display: inline-block;
    word-break: break-word;
    overflow: hidden;
}

.card-text {
    text-align: left;
}

.casesFooter {
    max-height: 62px !important;
}

.casesCardExpanded {
    min-width: 100% !important;
}

.casesBodyExpanded {
    max-height: 100% !important;
}

@media only screen and (max-width: 900px) {
    .casesCard {
        margin: 8px;
        width: 180px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
