body {
    max-width: 100%;
    background: url("../images/homeopata-siedlce.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    position: relative;
}

.card:not(.casesCard) {
    margin: 0 auto !important;
}

.navbar {
    background-color: rgba(255, 255, 255, 0.15) !important;
    margin-bottom: 25px;
}

.navbar-brand {
    margin-right: 40px !important;
    margin-top: -5px;
    margin-bottom: -5px;
}

.nav-item {
    margin-left: 10px;
    font-size: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 1px !important;
}

.nav-link {
    color: white !important;
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px !important;
}

.nav-link:active,
.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

#reservationBtn.active,
.nav-link.active {
    background-color: rgba(255, 255, 255, 0.20);
}

#reservationBtn {
    color: white !important;
    border-radius: 10rem !important;
    width: 260px;
    height: 50px;
    font-weight: 600;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
}

#reservationBtn:hover {
    border-color: white !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
}

.nav-link i {
    margin-right: 5px;
}

.card:not(.casesCard) {
    border-radius: 15px !important;
    max-width: 950px;
    margin: 0 auto;
    margin-top: 25px 0 25px 0;
    font-weight: 300 !important;
    background-color: rgba(255, 255, 255, 0.95);
}

.card-body:not(.casesBody) {
    min-height: 700px;
}

.card-header {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 23px;
    font-weight: 800;
    text-align: center;
}

.sectionTitle {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.sectionImg {
    width: 90% !important;
    border: 2px solid lightgray;
    border-radius: 15px;
    margin: auto;    
    display: block;
}

.button_main {
    padding: 30px;
    margin-top: 10px;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    color: #34A853;
}

.button_main button {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-left: 20px !important;
    color: #34A853 !important;
    border-radius: 10rem;
    border: 3px solid #34A853 !important;
}

.sectionLinks li a {
    color: #34A853;
    font-weight: 600;
}

.faqBody {
    width: 80%;
     margin: 0 auto;
}

@media only screen and (max-width: 900px) {
    .container-fluid {
        padding: 5px;
    }

    .card:not(.casesCard) {
        margin-top: 15px 0 15px 0;
    }

    .card-body:not(.casesBody) {
        padding: 12px;
    }

    .faqBody {
        width: 100%;
    }

    .react-search-box-dropdown {
        top: 110px !important;
        width: 190px !important;
    }
    
}

.react-search-box-dropdown {
    position: absolute;
    width: 240px;
    z-index: 1001;
    top: 46px;
}
