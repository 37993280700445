.second-modal {
    z-index: 1029
}

.notAvailableDate {
    color: grey;
}

.availableDate {
    color: #34A853;
    font-weight: 600;
}

.busyDate {
    color: #FBBC05;
}

.calendarDate {
    font-size: 16px;
    padding: 0px !important;
}

/* Radio Styles */

#durationSection * {
    --grey: #ededed;
    --blue: #34A853;
}

#durationSection h2 {
    margin: 0;
    font-weight: bold;
    grid-column: 1 / -1;
}

#durationSection p {
    font-size: 16px;
    margin-bottom: 0;
}

#durationSection .radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

#durationSection input[type="radio"] {
    appearance: none;
    opacity: 0;
    position: absolute;
}

#durationSection label {
    position: relative;
    padding: 48px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px 0px var(--grey);
    background-color: white;
    cursor: pointer;
}

#durationSection label::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    right: 16px;
    top: 16px;
    background: var(--grey);
}

#durationSection input[type="radio"]:checked+label {
    background: #ebfff2;
    outline: solid 3px var(--blue);
}

#durationSection input[type="radio"]:focus+label {
    outline: solid 3px var(--blue);
    outline-offset: 5px;
}

#durationSection input[type="radio"]:checked+label::after {
    background: var(--blue);
}


@media only screen and (max-width: 900px) {
    #durationSection .radio-label {
        padding: 20px;
    }
}


/* Calendar Styles */

@media only screen and (max-width: 900px) {
    .timepicker {
        margin-top: 15px;
    }

    .timepicker-container-outer {
        height: fit-content;
    }

    .buttons-container {
        position: static !important;
        bottom: auto !important;
        margin-top: 15px !important;
        width: 100% !important;
    }
}

.disabledBtn svg path {
    stroke: #8A8A8A !important;
}

.app-container {
    border-radius: 4px;
    overflow: hidden;
    width: 720px;
    height: auto;
    max-width: 100%;
    margin: auto;
}

.buttons-container {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 24px);
}

/* Timepicker Stuff */

.timepicker {
    display: inline-block;
    position: relative;
}

.timepicker-container-outer {
    max-width: 700px;
    display: block;
    text-align: center !important;
}

.timepicker-container-inner {
    width: 100%;
    height: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto !important;
}

.listElement {
    width: 100%;
    text-align: center;
    color: rgb(49, 49, 49);
    font-weight: 400;
    background-color: white;

    padding: 3px 10px 3px 10px;
    margin: 7px;
    border-radius: 10px;
    border: 2px solid white;
    cursor: pointer;
    display: inline-block;

    -webkit-box-shadow: 8px 8px 25px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 25px -12px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 25px -12px rgba(66, 68, 90, 1);

    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.listElement .col-4 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.activeBtn,
.availableTime:hover {
    border-color: #34A853;
    background-color: #ebfff2;
}

.activeTimeBtn,
.availableTimeOff:hover,
.blockedTimeOff:hover {
    border-color: #4285f4 !important;
    background-color: #cbdefd !important;
}

.busyTime {
    font-weight: 400;
    border-color: #4285f4;
    background-color: #cbdefd;
}

.blockedTime,
.blockedTimeOff {
    font-weight: 400;
    border-color: #FBBC05;
    background-color: #fff5d6;
}

.reservedTime {
    font-weight: 400;
    cursor: default !important;
    border-color: #EA4335 !important;
    background-color: #ffdedb !important;
}

.form-check-input:checked:focus:before {
    box-shadow: 0px 0px 13px #34A853 !important
}

.form-check-input:checked {
    background-color: #34A853 !important;
    border-color: #34A853 !important;
}

.userReservation {
    border-left: 2px solid #34A853;
}

@media only screen and (max-width: 900px) {
    .listElement > .row > .col-4,
    .listElement > .row > .col {
        padding: 0 4px 0 4px !important;
    }
}

@-webkit-keyframes select-time {
    0% {
        border-color: white;
        background-color: white;
    }

    100% {
        border-color: #34A853;
        background-color: #ebfff2;
    }
}

@keyframes select-time {
    0% {
        border-color: white;
        background-color: white;
    }

    100% {
        border-color: #34A853;
        background-color: #ebfff2;
    }
}

/* Datepicker Stuff */

.datepicker {
    position: relative;
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-family: "Roboto", sans-serif;
    overflow: hidden;
}

.datepicker-header {
    width: 100%;
    color: white;
    overflow: hidden;
}

.datepicker-title {
    width: 50%;
    float: left;
    height: 60px;
    line-height: 60px;
    padding: 0 15px;
    text-align: left;
    font-size: 20px;
}

.datepicker-subheader {
    width: 50%;
    float: left;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    padding: 0 15px;
    text-align: right;
}

.datepicker-calendar {
    background: white;
    border-radius: 10px;
    width: 100%;
    float: left;
    padding: 20px 15px 15px;
    max-width: 400px;
    display: block;
    margin: 5px;
    -webkit-box-shadow: 0px 0px 20px -14px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 20px -14px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 20px -14px rgba(66, 68, 90, 1);
}

.calendar-header {
    color: black;
    font-weight: bolder;
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
    position: relative;
}

.current-month-container {
    display: inline-block;
    height: 30px;
    position: relative;
}

.goback,
.goforward {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: -4px;
}

.goback path,
.goforward path {
    transition: stroke 0.15s ease;
}

.goback {
    float: left;
    margin-left: 3.8%;
}

.goforward {
    float: right;
    margin-right: 3.8%;
}

.calendar-day-header {
    width: 100%;
    position: relative;
}

.day-label {
    color: #8A8A8A;
    padding: 5px 0;
    width: 14.2857142%;
    display: inline-block;
    text-align: center;
    font-weight: 600 !important;
}

.datecontainer {
    width: 14.2857142%;
    display: inline-block;
    text-align: center;
    padding: 4px 0;
}

.datenumber {
    max-width: 35px;
    max-height: 35px;
    line-height: 35px;
    margin: 0 auto;
    color: #8A8A8A;
    position: relative;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    transition: all .25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
}

.no-hover .datenumber,
.no-hover .datenumber:hover,
.no-hover .datenumber:before,
.no-hover .datenumber:hover::before {
    cursor: default;
    color: #8A8A8A;
    background: transparent;
    opacity: 0.5;
}

.availableDate {
    cursor: pointer !important;
}

.notAvailableDate {
    cursor: default !important;
}

.no-hover .datenumber.day-selected {
    color: white;
}

.datenumber:not(.notAvailableDate):hover {
    color: white;
}

.datenumber:not(.notAvailableDate):before {
    content: '';
    display: block;
    position: absolute;
    height: 35px;
    width: 35px;
    border-radius: 100px;
    z-index: -1;
    background: transparent;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
    transition: all .25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
}

.datenumber:not(.notAvailableDate):hover::before {
    background: #87d39d;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.day-selected.availableDate {
    color: white !important;
}

.datenumber.day-selected:before {
    background: #34A853;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-animation: select-date .25s forwards;
    -moz-animation: select-date .25s forwards;
    animation: select-date .25s forwards;
}

@-webkit-keyframes select-date {
    0% {
        background: #87d39d;
    }

    100% {
        background: #34A853;
    }
}

@keyframes select-date {
    0% {
        background: #87d39d;
    }

    100% {
        background: #34A853;
    }
}

.errorMsg {
    margin-top: 25px;

    -webkit-animation: fadein 5s;
    -ms-animation: fadein 5s;
    -o-animation: fadein 5s;
    animation: fadein 5s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
