.react-search-box-dropdown {
    margin: 10px 0 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 0, rgba(0, 0, 0, 0.1) 0px 4px 11px;
    border-radius: 5px;
    max-height: 250px;
    -webkit-box-shadow: -1px 0px 24px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: -1px 0px 24px -12px rgba(66, 68, 90, 1);
    box-shadow: -1px 0px 24px -12px rgba(66, 68, 90, 1);

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.react-search-box-dropdown::-webkit-scrollbar {
    display: none; 
}

.card:not(.casesCard) {
    margin: 0 auto !important;
}

.react-search-box-dropdown>ul {
    padding: 0 !important;
    margin: 0 !important;
}

.react-search-box-dropdown>ul>li:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px;
}

.react-search-box-dropdown>ul>li:hover {
    background-color: #ccc;
    cursor: pointer;
}

.react-search-box-dropdown>ul>li {
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #cacaca96;
    border-top: 0;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.searchBarMobile {
    display: none;
}

.searchBar {
    display: block;
}

.react-search-box-dropdown-list-item {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 994px) {
    .searchBarMobile {
        display: block;
    }

    .searchBar {
        display: none;
    }

    
}

@media only screen and (max-width: 994px) {
    .dataSearchBar {
        padding: 12px !important;
    }

    .searchPhaseBar {
        width: 180px !important;
    }
}

.searchPhaseBar {
    width: 300px;
}

.dataSearchBar {
    padding: 12px 24px 12px 24px;
    border-bottom: 1px solid lightgray;
}

.dataTD>td {
    padding: 8px 0 8px 6px !important;
}

.statsStyle {
    font-size: 17px;
    font-weight: 600;
}

.statsStyle span {
    color: black !important;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
}
